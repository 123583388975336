import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const HipaaPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>HIPAA - The Dentists At Gateway Crossing</title>
      <meta name="description" content="The Dentists at Gateway Crossing and its employees and volunteers collect data through a variety of means. Click to read more of our HIPAA Notice of Privacy Practices Statement." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="HIPAA - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="The Dentists at Gateway Crossing and its employees and volunteers collect data through a variety of means. Click to read more of our HIPAA Notice of Privacy Practices Statement." />
      <meta property="og:url" content="https://thedentistsatgc.com/hipaa/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="HIPAA - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="The Dentists at Gateway Crossing and its employees and volunteers collect data through a variety of means. Click to read more of our HIPAA Notice of Privacy Practices Statement." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <div id="hero-section">
          <Container>
            <div className= "col-lg-12">
              <h1> HIPAA </h1>
            </div>
          </Container>
          </div>
        <section id ="policy-section-2" className ="page-section text-justify">
          <Container>
            <div className ="col-lg-12">
              <h3> The Dentists at Gateway Crossing HIPAA Notice of Privacy Practices Statement </h3>
            </div>
            <div className ="col-lg-12">
              <p><strong>Notice of Information Practices and Privacy Statement</strong></p>
            </div>
            <div className ="col-lg-12">
              <p><strong>For The Dentists at Gateway Crossing</strong></p>
            </div>
            <div className ="col-sm-4">
              <p>621W Broadway STE600,McCordsville,<a href="tel:3176439434">(317) 643-9434</a></p>
            </div>
            <div className ="col-lg-12">
              <p><strong>How We Collect Information About You:</strong> The Dentists at Gateway Crossing. (TDAGC) and its employees and volunteers collect data through a variety of means including but not necessarily limited to letters, phone calls, emails, voicemails, and from the submission of applications that are either required by law or necessary to process applications or other requests for assistance through our organization.</p>
            </div>
            <div className ="col-lg-12">
              <p><strong>What We Do Not Do With Your Information:</strong> Information about your financial situation and medical conditions and care that you provide to us in writing, via email, on the phone (including information left on voicemails), contained in or attached to applications, or directly or indirectly given to us, is held in strictest confidence.</p>
            </div>
            <div className ="col-lg-12">
              <p>We do not give out, exchange, barter, rent, sell, lend, or disseminate any information about applicants or clients who apply for or actually receive our services that are considered patient confidential, is restricted by law, or has been specifically restricted by a patient/client in a signed HIPAA consent form. </p>
            </div>
          </Container>
          <Container>
            <div className ="col-lg-12">
              <p><strong>How We Do Use Your Information:</strong> Information is only used as is reasonably necessary to process your application or to provide you with health or counseling services which may require communication between TDAGC and health care providers, medical product or service providers, pharmacies, insurance companies, and other providers necessary to: verify your medical information is accurate; determine the type of medical supplies or any health care services you need including, but not limited to; or to obtain or purchase any type of medical supplies, devices, medications, insurance,</p>
              <p>If you apply or attempt to apply to receive assistance through us and provide information with the intent or purpose of fraud or that results in either an actual crime of fraud for any reason including willful or un-willful acts of negligence whether intended or not, or in any way demonstrates or indicates attempted fraud, your non-medical information can be given to legal authorities including police, investigators, courts, and/or attorneys or other legal professionals, as well as any other information as permitted by law.</p>
            </div>
          </Container>
          <Container>
            <div className ="col-lg-12">
              <p><strong> Information We Do Not Collect:</strong> We do not use cookies on our website to collect date from our site visitors. We do not collect information about site visitors except for one hit counter on the main index page (www.yourwebpage.org) that simply records the number of visitors and no other data. We do use some affiliate programs that may or may not capture traffic date through our site. To avoid potential data capture that you visited a diabetes website simply do not click on any of our outside affiliate links.</p>
              <p> <strong>Limited Right to Use Non-Identifying Personal Information From Biographies, Letters, Notes, and Other Sources:</strong> Any pictures, stories, letters, biographies, correspondence, or thank you notes sent to us become the exclusive property of TDAGC. We reserve the right to use non-identifying information about our clients (those who receive services or goods from or through us) for fundraising and promotional purposes that are directly related to our mission.</p>
            </div>
            <div className ="col-lg-12">
              <p>Clients will not be compensated for use of this information and no identifying information (photos, addresses, phone numbers, contact information, last names or uniquely identifiable names) will be used without client’s express advance permission.</p>
            </div>
            <div className ="col-lg-12">
              <p>You may specifically request that NO information be used whatsoever for promotional purposes, but you must identify any requested restrictions in writing. We respect your right to privacy and assure you no identifying information or photos that you send to us will ever be publicly used without your direct or indirect consent.</p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default HipaaPage

export const query = graphql`
  query HipaaQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
